<template>
  <div>
    <el-page-header @back="goBack" content="专家详情"> </el-page-header>
    <div class="meetingBox">
      <div v-for="(t, i) in detail" :key="i">
        <div class="meetingDay">日期：{{ t.Date }}</div>
        <el-table :data="t.Meetings" stripe style="width: 100%">
          <el-table-column prop="DoctorName" label="姓名"> </el-table-column>
          <el-table-column prop="Group" label="学组"></el-table-column>
          <el-table-column prop="Hospital" label="医院" width="150"></el-table-column>
          <el-table-column prop="Topic" label="课题" width="150"></el-table-column>
          <el-table-column label="角色">
            <template slot-scope="scope">
              <div>{{scope.row.Identype | getIdentype }}</div>
            </template>
          </el-table-column>
          <el-table-column label="时间" width="100">
            <template slot-scope="scope">
              <div>{{scope.row.Time.substring(18) }}</div>
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button
                type="text"
                size="medium"
                @click.native.prevent="clickVideo(scope.row)"
                >看直播</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import { GetMeetingInfo } from "@/api/index.js";

export default {
  name: "ExpertDetail",
  data() {
    return {
      doctorId: this.$route.params.doctorId || "",
      doctorInfo: JSON.parse(sessionStorage.PCExpertInfo) || {},
      detail: [],
    };
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    async GetMeetingInfoAsync() {
      const r = await GetMeetingInfo({
        doctorId: this.doctorId,
      });
      if (r.code === 1) {
        // console.log(r.data);
        this.detail = r.data;
      }
    },
    clickVideo(row){
      console.log(row);
    }
  },
  created() {
    this.GetMeetingInfoAsync();
  },
  filters: {
    getIdentype(v) {
      return v === 0 ? "主持" : "讲者";
    },
  },
};
</script>

<style scoped>
.meetingDay {
  margin: 25px 0 15px;
  font-size: 18px;
}
</style>